import React from 'react';
import {
  IoHomeOutline,
  IoLogoRss,
  IoDocumentTextOutline,
  IoSearchOutline,
  IoPersonAddOutline,
  IoEnterOutline,
  IoMenuOutline,
  IoHelpCircleOutline,
} from 'react-icons/io5';

/* ********************************
* Roles:
*
* null = not logged in
* CS = care seekers
* CR = care workers
* ADMIN = admin users
* SUPPORT = support coordinators
* ENGAGEADMIN = providers
* ALL = all logged in users
******************************** */
const engageURL = process.env.REACT_APP_CS_OAUTH_URL;

export const mainLinks = [
  { id: 'home', label: 'Home', to: '/', icon: <IoHomeOutline />, roles: [null] },
  { id: 'blog', label: 'Blog', href: 'https://blog.careseekers.com.au', icon: <IoLogoRss />, roles: ['ALL'] },
  { id: 'pj', label: 'Post a Job', to: '/careseeker/post-a-job', icon: <IoDocumentTextOutline />, roles: [null, 'CS'] },
  { id: 'sw', label: 'Find Workers', to: '/care-workers/search', icon: <IoSearchOutline />, roles: [null, 'CS'] },
  { id: 'worker', label: 'Become a Worker', to: '/careworker', icon: <IoPersonAddOutline />, roles: [null] },
  { id: 'sj', label: 'Find Jobs', to: '/jobs/search', icon: <IoSearchOutline />, roles: ['CR'] },
  { id: 'login', label: 'Login', to: '/login', icon: <IoEnterOutline />, roles: [null] },
  { id: 'csdash', label: 'Dashboard', to: '/dashboard', icon: <IoMenuOutline />, roles: ['CS'] },
  { id: 'cwdash', label: 'My Dashboard', to: '/care-worker/dashboard', icon: <IoMenuOutline />, roles: ['CR'] },
  // { id: 'adm', label: 'Admin Dashboard', to: '/admin', icon: <IoMenuOutline />, roles: ['ADMIN'] },
  { id: 'adm', label: 'Admin Dashboard', href: '/admins', icon: <IoMenuOutline />, roles: ['ADMIN'] },
  { id: 'sc', label: 'SC Dashboard', to: '/support-coordinator/dash', icon: <IoMenuOutline />, roles: ['SUPPORT'] },
  { id: 'ea', label: 'Engage Platform', to: engageURL, icon: <IoMenuOutline />, roles: ['ENGAGEADMIN'] },
];

export const supportLinks = [
  { id: 'CS', label: 'Help Centre', icon: <IoHelpCircleOutline />, href: 'https://helpcentre.careseekers.com.au/hc/en-us/categories/9019797504783-Careseekers' },
  { id: 'CR', label: 'Help Centre', icon: <IoHelpCircleOutline />, href: 'https://helpcentre.careseekers.com.au/hc/en-us/categories/9019831312015-Care-and-Support-Workers' },
  { id: 'ENGAGEADMIN', label: 'Help Centre', icon: <IoHelpCircleOutline />, href: 'https://helpcentre.careseekers.com.au/hc/en-us/categories/9019854399503-Providers' },
  { id: 'SUPPORT', label: 'Help Centre', icon: <IoHelpCircleOutline />, href: 'https://helpcentre.careseekers.com.au/hc/en-us/categories/9019838333455-Support-Coordinators' },
];

export const aboutLinks = [
  { id: 'about', label: 'About Careseekers', to: '/about-us' },
  { id: 'found', label: 'Meet the Founders', to: '/founders' },
  { id: 'workers', label: 'The Workers', to: '/workers' },
  { id: 'test', label: 'Testimonials', to: '/testimonials' },
  { id: 'media', label: 'Media Centre', to: '/media-centre' },
];

export const howItWorks = [
  { id: 'sw', label: 'Support Workers', to: '/careworker' },
  { id: 'sc', label: 'Support Coordinators', to: '/support-coordinator' },
  { id: 'prov', label: 'Providers', to: '/providers' },
  { id: 'aged', label: 'Aged Care', to: '/services/aged-care-workers' },
  { id: 'dis', label: 'Disability Support', to: '/services/disability-support-workers' },
  { id: 'mh', label: 'Mental Health Support', to: '/services/mental-health-support-workers' },
  { id: 'ndis', label: 'NDIS', to: '/ndis-funded-support' },
  { id: 'hcp', label: 'Home Care Package', to: '/home-care-package' },
];

export const resources = [
  { id: 'ins', label: 'Insurances', to: '/insurance-policy' },
  { id: 'feed', label: 'Feedback & Complaints', to: '/feedback-complaints-incident-reports' },
  { id: 'covid', label: 'COVID-19', to: '/covid-19' },
  { id: 'worksafety', label: 'Work Health and Safety', to: '/work-health-and-safety' },
];
