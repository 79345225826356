import React from 'react';
import { IoPeopleOutline, IoAddSharp, IoLink, IoShareSocialOutline, IoHelp } from 'react-icons/io5';

export const internalLinks = [
  {
    to: '/support-coordinator/dashboard',
    label: 'My Clients',
    icon: <IoPeopleOutline />,
  },
  {
    to: '/support-coordinator/add-client',
    label: 'Add Client',
    icon: <IoAddSharp />,
  },
  {
    to: '/support-coordinator/request-linking',
    label: 'Request Linking',
    icon: <IoLink />,
  },
];

export const externalLinks = [
  {
    href: 'https://www.careseekers.com.au/referral/disability/',
    label: 'Make a Referral',
    icon: <IoShareSocialOutline />,
  },

  {
    href: 'https://helpcentre.careseekers.com.au/hc/en-us/categories/9019838333455-Support-Coordinators',
    label: 'Help Centre',
    icon: <IoHelp />,
  },
];
